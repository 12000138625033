@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import url("react-toastify/dist/ReactToastify.css");
body {
  /* overflow: hidden; */
  height: 100vh;
}

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

.css-f3jnds-MuiDataGrid-columnHeaders {
  color: #fff !important;
}

.css-hpjhlg-MuiTablePagination-root {
  color: #fff !important;
}
.cardBody {
  padding: "20px";
}
.danger {
  color: #ff6156;
  /* color: #ef9a9a; */
  margin: 0 4px;
}
.dark {
  color: #6ba093;
  margin: 0 4px;
}
.blue {
  /* color: red; */
  margin: 0 4px;
}
.modal-header {
  font-size: 18px !important;
  color: #158582;
}

/* CUSTOM CSS START */

.MuiDataGrid-columnHeader,
.MuiTablePagination-root,
.MuiDataGrid-columnHeader .MuiSvgIcon-root,
.MuiTablePagination-root .MuiSvgIcon-root {
  color: #fff !important;
}

.MuiDataGrid-columnHeader:focus-within,
.css-oc39s6-MuiDataGrid-root .MuiDataGrid-cell:focus-within,
.MuiDataGrid-cell:focus {
  outline: 0 !important;
}

.pro-inner-item:hover,
.pro-menu-item.active {
  color: #0e144c !important;
}

.pro-sidebar {
  height: 100vh !important;
}

@media (min-width: 768px) {
  .pro-sidebar-toggle-btn {
    display: none !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  background-color: transparent !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
}

.modalPadding {
  padding: 8px;
}

.app .btn-toggle {
  cursor: pointer;
  width: 35px;
  height: 35px;
  color: #747474;
  background: #d7d7d7;
  text-align: center;
  border-radius: 50%;
  /* font-size: 15px; */
  /* padding: 10px; */
  display: none;
  /* line-height: 35px; */
  justify-content: center;
  align-items: center;
}
.MuiDataGrid-selectedRowCount {
  visibility: hidden !important;
}

/* .MuiInputLabel-formControl {
  z-index: 0 !important;
} */
.beforeTree {
  display: flex;
  align-items: center;
}

.reactSelectFocus .react-select__control--is-focused,
.reactSelectFocus .react-select__control--is-focused:hover,
.reactSelectFocus .css-94h981-control,
.reactSelectFocus .css-94h981-control:hover {
  border-color: #000;
  box-shadow: 0 0 0 1px #000;
}

.cursor-pointer {
  cursor: pointer !important;
}

.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3 !important;
}

@media (max-width: 768px) {
  .responsive-head {
    width: 100%;
  }
  .app .btn-toggle {
    display: inline-flex;
  }
}
.react-checkbox-tree .MuiSvgIcon-root {
  margin-bottom: -5px;
}
.rct-collapse {
  padding: 0;
  width: 24px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
}
button {
  cursor: pointer;
}

.MuiDataGrid-virtualScroller {
  font-size: 16px;
}

.addressIndex {
  position: absolute;
  background-color: #fff;
  padding: 0 4px;
  top: -13px;
  left: 10px;
  border: 1px solid #c4c4c4;
  border-radius: 0.25rem;
}
.addressIndex-2 {
  position: absolute;
  background-color: #fff;
  padding: 0 4px;
  top: -13px;
  left: 10px;
  border: 1px solid #c4c4c4;
  border-radius: 0.25rem;
  font-size: 14px;
}

.addressDeleteIcon {
  color: #adadad;
  position: absolute;
  right: 10px;
  top: -13px;
  background: #fff;
  transition: 0.5s all;
  border: 1px solid #c4c4c4;
  border-radius: 0.25rem;
}
.addressDeleteIcon:hover {
  color: #8d8d8d;
}

.productViewDesc p {
  margin-top: 0;
}

.topBar {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 999;
}
@media (max-width: 480px) {
  .topBar {
    position: relative;
  }
}

.fullscreen {
  position: relative;
  z-index: 1302;
}

.MuiDataGrid-footerContainer {
  border-radius: 0 0 4px 4px;
}

.notificationBadge span {
  background: #f44336;
  font-weight: bold;
  color: #fff;
}

.accordianNotificationBadge {
  background: #f44336;
  font-weight: bold;
  color: #fff;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  line-height: 15px;
  font-size: 12px;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 3px solid #f44336;
}

.orderTableSelect fieldset {
  border: none;
}
.orderTableSelect > div {
  padding-left: 0;
}

.userMenu div:nth-child(3) {
  width: 210px;
}

.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.z-index-1 {
  position: relative;
  z-index: 1;
}
.z-index-2 {
  position: relative;
  z-index: 2;
}
.z-index-3 {
  position: relative;
  z-index: 3;
}
.z-index-4 {
  position: relative;
  z-index: 4;
}
.z-index-5 {
  position: relative;
  z-index: 5;
}
.z-index-6 {
  position: relative;
  z-index: 6;
}
.z-index-7 {
  position: relative;
  z-index: 7;
}
.z-index-8 {
  position: relative;
  z-index: 8;
}
.z-index-9 {
  position: relative;
  z-index: 9;
}
.z-index-10 {
  position: relative;
  z-index: 10;
}
.react-datepicker-popper {
  position: relative;
  z-index: 11 !important;
}
.img-product {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  max-width: 100%;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}
.img-sub-product {
  border: 1px solid #ddd;
  cursor: pointer;
  border-radius: 4px;
  padding: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 60px;
  height: 42px;
  object-fit: cover;
}
.no-img-sub-product {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 60px;
  height: 42px;
  object-fit: cover;
}
.logo {
  max-width: 100%;
}

.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus-within {
  outline: none !important;
  outline-offset: 0 !important;
}

[data-field="actions"] {
  padding-left: 0 !important;
}

.searchBar {
  width: 400px;
}
.searchBar .search_input input {
  padding: 0 14px !important;
}
.searchBar .searchBtn {
  padding: 0 8px !important;
}

.orderSearchTabPanel1 {
  overflow: auto;
}
.orderSearchTabPanel1 > div {
  padding-top: 0;
}

.searchClearBtn {
  padding: 0 !important;
}
.searchClearBtn svg {
  font-size: 18px !important;
}

.imageAlertThumb .MuiPaper-root {
  margin: 0 !important;
}

.react-select-labels {
  position: absolute;
  top: -20%;
  left: 2%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  background: #fff;
  padding: 0 5px;
}
.react-select-labels-2 {
  position: absolute;
  top: -20%;
  left: 2%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  background: #fff;
  padding: 0 5px;
}

.fileUploaderLabel,
.fileUploaderLabel-1,
.fileUploaderLabel-11,
.fileUploaderLabel-2 {
  position: absolute;
  right: 2%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  background: #fff;
  padding: 0 5px;
}
.fileUploaderLabel {
  top: -20%;
}
.fileUploaderLabel-1 {
  top: -22%;
}
.fileUploaderLabel-11 {
  top: -10%;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  font-size: 14px !important;
}
.MuiTooltip-popper .MuiTooltip-arrow {
  left: 50% !important;
  transform: translate(-50%, 0) !important;
}

.createOrderSelectAddress .react-select__control {
  background-color: #fff;
}

.searchBar {
  background-color: #fff !important;
}

.MuiInputBase-inputSizeSmall {
  padding: 11.5px 14px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

button:disabled,
input:disabled,
input[readonly] {
  /* background: #ebebeb !important; */
  cursor: not-allowed;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.6) !important;
}
button:disabled {
  -webkit-text-fill-color: #fff !important;
}
button:disabled:hover {
  -webkit-text-fill-color: #fff !important;
  background-color: #3d464d;
}

.radioPad .MuiButtonBase-root {
  padding: 0 8px;
}

.createOrderDialog {
  min-height: 80vh;
  height: 100%;
}

.createOrderDialogFooter {
  border-top: 1px solid #d5d4d4;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 16px !important;
  background: #fff;
  z-index: 20;
}

.addPaymentSelect .MuiSelect-select {
  padding: 11.5px 14px;
}

.orderViewStatusSelect .orderTableSelect > div {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 20px !important;
}
.orderViewStatusSelect .orderTableSelect > svg {
  top: 0;
  right: -5px;
}

.quickActionBtn {
  background: #f2f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 4px;
  padding: 8px;
  border-radius: 4px;
  transition: 0.5s all;
  cursor: pointer;
}
.quickActionBtn:hover {
  background-color: #dadada;
}

.inputGroupTextField .MuiInputBase-root {
  border-radius: 4px 0 0 0;
}
.inputGroupDateField .MuiInputBase-root {
  border-radius: 0 4px 0 0;
}
.inputGroupButton {
  border-radius: 0 0 4px 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3d464d;
  color: #fff;
  padding: 0 15px;
  justify-content: space-between;
  align-items: center;
}
#inputGroupButtonWImg label,
#inputGroupButtonWImg .custAddImg {
  padding: 0 15px;
}
#inputGroupButtonWImg .custAddImg {
  position: relative;
  display: flex;
  z-index: 1;
  cursor: pointer;
}
#inputGroupButtonWoImg label {
  width: 100%;
  padding: 0;
  text-align: center;
}
.customInputFileField {
  display: none !important;
}

.inputGroupButtonLabels {
  border-radius: 4px 0 0 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c4c4c4;
  color: #040509;
  padding: 0 15px;
  height: 100%;
  width: 100%;
}
.inputGroupButtonInputs .MuiInputBase-root {
  border-radius: 0 4px 4px 0 !important;
}

.inputGroupButtonCustom {
  border-radius: 0 4px 4px 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3d464d;
  color: #fff;
  padding: 0 15px;
  height: 100%;
  width: 100%;
  border: none;
  transition: 0.5s all;
}
.inputGroupButtonCustom:hover {
  background: #3d426f;
  color: #fff;
}

.inputGroupButtonCustomInputs .MuiInputBase-root {
  border-radius: 4px 0 0 4px !important;
}
.jodit-status-bar .jodit-status-bar__item-right:last-child {
  display: none;
}
/* .jodit-status-bar-link {
  display: none;
} */

@media (max-width: 768px) {
  /* .mdHide {
    display: none;
  } */
  .searchBar {
    width: 100%;
  }
}

.dashboardCardIcon svg {
  position: absolute;
  top: 50%;
  right: 10px;
  color: #8e91ab;
  /* color: #d8d9e2; */
  font-size: 80px;
  transform: translateY(-50%) rotate(-30deg);
  transition: 0.5s all;
}
.dashboardCardLinks:hover .dashboardCardIcon svg {
  transform: translateY(-50%) rotate(0deg);
}

/* Customer view profile page customer level tags CSS start */

.customer-medal {
  position: relative;
  cursor: default;
  user-select: none;
}
.customer-medal__circle {
  font-size: 20px;
  font-weight: 600;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  color: white;
  text-align: center;
  line-height: 28px;
  vertical-align: middle;
  position: relative;
  border-width: 0.2em;
  border-style: solid;
  z-index: 1;
}
.customer-medal__circle.customer-medal__circle--platinum {
  box-shadow: inset 0 0 0 #e0e4e9, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #dadce7;
  text-shadow: 0 0 4px #cacfd6;
  background: linear-gradient(to bottom right, #cbcfd6 50%, #b8bdca 50%);
}
.customer-medal__circle.customer-medal__circle--gold {
  box-shadow: inset 0 0 0 #b67d05, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #fadd40;
  text-shadow: 0 0 4px #9d6c04;
  background: linear-gradient(to bottom right, #f9ad0e 50%, #e89f06 50%);
}
.customer-medal__circle.customer-medal__circle--silver {
  box-shadow: inset 0 0 0 #a7b2b8, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #edeff1;
  text-shadow: 0px 0px 4px #98a6ad;
  background: linear-gradient(to bottom right, #d1d7da 50%, #c3cbcf 50%);
}
.customer-medal__circle.customer-medal__circle--bronze {
  box-shadow: inset 0 0 0 #955405, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #f7bb23;
  text-shadow: 0 0 4px #7d4604;
  background: linear-gradient(to bottom right, #df7e08 50%, #c67007 50%);
}
.customer-medal__ribbon {
  content: "";
  display: block;
  position: absolute;
  border-style: solid;
  border-width: 6px 8px;
  width: 0;
  height: 16px;
  top: 32px;
}
.customer-medal__ribbon--left {
  left: 0;
  border-color: #fc402d #fc402d transparent #fc402d;
  transform: rotate(20deg) translateZ(-32px);
}
.customer-medal__ribbon--right {
  left: 20px;
  border-color: #f31903 #f31903 transparent #f31903;
  transform: rotate(-20deg) translateZ(-48px);
}

/* Customer view profile page customer level tags CSS end */

/* Scrollbar css start */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fcfcfc;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3d464d;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #6a7883;
} */

@media print {
  body.barcode * {
    visibility: hidden;
  }
  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}
/* @page {
  height: 100px;
  width: 100%;
} */

/* Scrollbar css end */

#ReactSimpleImageViewer {
  z-index: 1010;
  margin-left: 0 !important;
}
.ql-editor {
  min-height: 100px;
}
.text-capitalize {
  text-transform: capitalize;
}

.customFileInput input[type="file"] {
  padding: 6px 4px 7px 0 !important;
}
.customFileInput input[type="file"]::file-selector-button {
  border: 1px solid #c4c4c4;
  padding: 8px 10px;
  color: rgba(0, 0, 0, 0.6);
  outline: none;
  margin-top: -6px;
  border-radius: 4px;
  background-color: #eeeeee;
  transition: 0.5s all;
  margin-right: 6px;
  cursor: pointer;
}

.notificationAccDetails {
  padding: 0 !important;
  overflow-y: auto;
  max-height: calc(100vh - 80px - (3 * 48px));
}
.notifictionAccHead {
  background-color: #3d464d !important;
  color: #fff !important;
  /* background-color: rgba(61, 70, 77, 0.2) !important; */
}

@media (max-width: 480px) {
  #notificationDrawer {
    width: 85vw;
    /* width: 300px; */
  }
}

.datePickerStyling,
.datePickerStyling-1 {
  font-weight: 400;
  font-size: 1rem;
  color: #333 !important;
  padding: 13px 14px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #c4c4c4;
  width: 100%;
  height: 100%;
}
.datePickerStyling {
  border-radius: 0 4px 0 0;
}
.datePickerStyling-1 {
  border-radius: 4px;
}
.react-datepicker__month {
  user-select: none;
}

.dashboardTopCards {
  display: grid;
  /* grid-template-columns: repeat(5, 1fr); */
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  /* grid-auto-rows: 140px; */
  gap: 19px;
}
@media (max-width: 1766px) {
  .dashboardTopCards {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
}
@media (max-width: 1600px) {
  .dashboardTopCards {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
@media (max-width: 1400px) {
  .dashboardTopCards {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  }
}

.searchProductTab .MuiTabs-vertical,
.searchProductTab div[role="tabpanel"] {
  overflow-y: auto;
  max-height: 80vh;
}
.searchProductTableBody tr:last-child td {
  border-bottom: none !important;
}

.navLinks {
  color: #fffade;
  text-decoration: none;
}

.functionalKey {
  background-color: #f0f0f0;
  border: 1px solid #000;
  padding: 0 20px 2px;
  border-radius: 2px;
  font-size: 14px;
  user-select: none;
  pointer-events: none;
}

.mobModalFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 480px) {
  .mobModalFooter {
    flex-direction: column;
    justify-content: center;
    gap: 6px;
  }
}

@media print {
  .pagebreak {
    page-break-after: always;
  } /* page-break-after works, as well */
}

.orderSearchProductDialog {
  padding-bottom: 16px !important;
}

.searchProductDialog .MuiDialog-container .MuiPaper-root {
  max-height: none !important;
}

/* CUSTOM CSS END */

/* media queries */

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {
  .orderCompanyName {
    display: none !important;
  }
  .orderProductName {
    display: none !important;
  }
  .orderPayment {
    display: none !important;
  }
  .orderStatus {
    display: none !important;
  }
  .orderAmount {
    display: none !important;
  }
  .orderDate {
    display: none !important;
  }
  .orderNotes {
    display: none !important;
  }
} */

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1800px) {
}
